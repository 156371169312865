import axios from 'axios';
import { baseUrl, BasicAuth, routes } from './ApiUtils';
import Article from './Article';
import ArticleLight from './ArticleLight';
import Category from './Category';
import Comment from './Comment';
import MailBean from './MailBean';
import Media from './Media';
import PaginatedArticle from './PaginatedArticle';
import PaginatedComment from './PaginatedComment';
import PaginatedMedia from './PaginatedMedia';
import PaginatedResource from './PaginatedResource';
import RequestParam from './RequestParam';
import Resource from './Resource';
import ResourceSearchCriteria from './ResourceSearchCriteria';
import Tag from './Tag';

const ApiCall = axios.create({
    baseURL: baseUrl
})

const AUTH_TOKEN_KEY = 'AUTH-TOKEN'

const httpHeaders = (secure = true, contentType = "application/json") => {
    let authToken: string | null = btoa(`${BasicAuth.user}:${BasicAuth.pswd}`)
    if(secure) {
        authToken = sessionStorage.getItem(AUTH_TOKEN_KEY)
    }
    return {
        'Content-Type': contentType,
        authorization: `Basic ${authToken}`
    }
}

export function login(username: string, password: string) {
    const encoding: string = btoa(`${username}:${password}`)
    sessionStorage.setItem(AUTH_TOKEN_KEY, encoding);
}

export function logout() {
    sessionStorage.removeItem(AUTH_TOKEN_KEY);
}

export function isAuthenticated() {
    const isAuth = sessionStorage.getItem(AUTH_TOKEN_KEY) !== null;
    return isAuth;
}

export const loadTags =async () => new Promise<Tag[]>((resolve, reject) => {
    ApiCall.get(`/public${routes.tags}`, {
        headers: httpHeaders(false)
    }).then(resp => {
        const results = resp.data as Tag[]
        resolve(results);
    }).catch(err => {
        reject(err);
    })
})

export const loadCategories =async () => new Promise<Category[]>((resolve, reject) => {
    ApiCall.get(`/public${routes.categories}`, {
        headers: httpHeaders(false)
    }).then(resp => {
        const results = resp.data as Category[]
        resolve(results);
    }).catch(err => {
        reject(err);
    })
})

export const addTag =async (tag: Tag) => new Promise<Tag>((resolve, reject) => {
    ApiCall.post(routes.tags, tag, {
        headers: httpHeaders()
    }).then(resp => {
        const result = resp.data as Tag
        resolve(result);
    }).catch(err => {
        reject(err);
    })
})

export const updateTag =async (tag: Tag) => new Promise<Tag>((resolve, reject) => {
    ApiCall.put(`${routes.tags}/${tag.id}`, tag, {
        headers: httpHeaders()
    }).then(resp => {
        const result = resp.data as Tag
        resolve(result);
    }).catch(err => {
        reject(err);
    })
})

export const deleteTag =async (tag: Tag) => new Promise<void>((resolve, reject) => {
    ApiCall.delete(`${routes.tags}/${tag.id}`, {
        headers: httpHeaders()
    }).then(_resp => {
        resolve();
    }).catch(err => {
        reject(err);
    })
})

export const addCategory =async (cat: Category) => new Promise<Category>((resolve, reject) => {
    ApiCall.post(routes.categories, cat, {
        headers: httpHeaders()
    }).then(resp => {
        const result = resp.data as Category
        resolve(result);
    }).catch(err => {
        reject(err);
    })
})

export const updateCategory =async (cat: Category) => new Promise<Category>((resolve, reject) => {
    ApiCall.put(`${routes.categories}/${cat.id}`, cat, {
        headers: httpHeaders()
    }).then(resp => {
        const result = resp.data as Category
        resolve(result);
    }).catch(err => {
        reject(err);
    })
})

export const deleteCategory =async (cat: Category) => new Promise<void>((resolve, reject) => {
    ApiCall.delete(`${routes.categories}/${cat.id}`, {
        headers: httpHeaders()
    }).then(_resp => {
        resolve();
    }).catch(err => {
        reject(err);
    })
})

export const saveArticle =async (article: Article) => new Promise<Article>((resolve, reject) => {
    let requestCall = article.id === undefined?
    ApiCall.post(routes.articles, article, {
        headers: httpHeaders()
    })
    :
    ApiCall.put(`${routes.articles}/${article.id}`, article, {
        headers: httpHeaders()
    })
    //***********
    requestCall.then(resp => {
        const result = Article.map(resp.data)
        resolve(result);
    }).catch(err => {
        reject(err);
    })
})

export const loadArticles =async (params: RequestParam, page = 0, size = 25) => new Promise<PaginatedArticle>((resolve, reject) => {
    params.setParam("page", page.toString())
    params.setParam("size", size.toString())
    ApiCall.get(`/public${routes.articles}${params.toQueryString()}`, {
        headers: httpHeaders(false)
    }).then(resp => {
        const results = new PaginatedArticle(
            resp.data.page, 
            resp.data.totalPages, 
            resp.data.articles.map((article: any) => ArticleLight.map(article)))
        resolve(results)
    }).catch(err => {
        reject(err);
    })
})

export const getArticle =async (id: number) => new Promise<Article>((resolve, reject) => {
    ApiCall.get(`/public${routes.articles}/${id}`, {
        headers: httpHeaders(false)
    }).then(resp => {
        const result = Article.map(resp.data)
        resolve(result);
    }).catch(err => {
        reject(err);
    })
})

export const publishArticle =async (article: ArticleLight) => new Promise<ArticleLight>((resolve, reject) => {
    ApiCall.put(`${routes.articles}/${article.id}/publish`, null, {
        headers: httpHeaders()
    }).then(resp => {
        const result = ArticleLight.map(resp.data)
        resolve(result);
    }).catch(err => {
        reject(err);
    })
})

export const deleteArticle =async (article: ArticleLight) => new Promise<void>((resolve, reject) => {
    ApiCall.delete(`${routes.articles}/${article.id}`, {
        headers: httpHeaders()
    }).then(_resp => {
        resolve();
    }).catch(err => {
        reject(err);
    })
})

export const postComment =async (comment: Comment, articleId: number, commentId?: number) => new Promise<Comment>((resolve, reject) => {
    let params: RequestParam = new RequestParam()
    params.setParam("article_id", articleId.toString())
    if(commentId !== undefined) {
        params.setParam("comment_id", commentId.toString())
    }
    ApiCall.post(`${routes.comments}${params.toQueryString()}`, comment, {
        headers: httpHeaders(false)
    }).then(resp => {
        const result = Comment.map(resp.data)
        resolve(result);
    }).catch(err => {
        reject(err);
    })
})

export const loadComments =async (articleId: number, page = 0, commentId?: number, size = 15) => new Promise<PaginatedComment>((resolve, reject) => {
    let params: RequestParam = new RequestParam()
    params.setParam("page", page.toString())
    params.setParam("size", size.toString())
    params.setParam("article_id", articleId.toString())
    if(commentId !== undefined) {
        params.setParam("comment_id", commentId.toString())
    }
    ApiCall.get(`${routes.comments}${params.toQueryString()}`, {
        headers: httpHeaders(false)
    }).then(resp => {
        const results = new PaginatedComment(
            resp.data.page, 
            resp.data.totalPages, 
            resp.data.comments.map((comment: any) => Comment.map(comment)))
        resolve(results)
    }).catch(err => {
        reject(err);
    })
})

export const deleteComment =async (commentId: number) => new Promise<void>((resolve, reject) => {
    ApiCall.delete(`${routes.comments}/${commentId}`, {
        headers: httpHeaders()
    }).then(_resp => {
        resolve();
    }).catch(err => {
        reject(err);
    })
})

export const addResource =async (data: FormData) => new Promise<Resource>((resolve, reject) => {
    ApiCall.post(`${routes.resources}`, data, {
        headers: httpHeaders(true, "multipart/form-data")
    }).then(resp => {
        const result = Resource.map(resp.data)
        resolve(result);
    }).catch(err => {
        reject(err);
    })
})

export const loadResources =async (criteria: ResourceSearchCriteria, page = 0, size = 25) => new Promise<PaginatedResource>((resolve, reject) => {
    let params: RequestParam = criteria.toRequestParams()
    params.setParam("page", page.toString())
    params.setParam("size", size.toString())
    
    ApiCall.get(`${routes.resources}${params.toQueryString()}`, {
        headers: httpHeaders(false)
    }).then(resp => {
        const results = new PaginatedResource(
            resp.data.page, 
            resp.data.totalPages, 
            resp.data.resources.map((resource: any) => Resource.map(resource)))
        resolve(results)
    }).catch(err => {
        reject(err);
    })
})

export const deleteResource =async (resourceId: number) => new Promise<void>((resolve, reject) => {
    ApiCall.delete(`${routes.resources}/${resourceId}`, {
        headers: httpHeaders()
    }).then(_resp => {
        resolve();
    }).catch(err => {
        reject(err);
    })
})

export const loadResourcesTopics =async () => new Promise<string[]>((resolve, reject) => {
    ApiCall.get(`${routes.resources}/topics`, {
        headers: httpHeaders(false)
    }).then(resp => {
        const results = resp.data as string[]
        resolve(results);
    }).catch(err => {
        reject(err);
    })
})

export const sendMail =async (data: MailBean) => new Promise<void>((resolve, reject) => {
    ApiCall.post(routes.mailSend, data, {
        headers: httpHeaders(false)
    }).then(_resp => {
        resolve();
    }).catch(err => {
        reject(err);
    })
})

export const subscribeToMailingList =async (email: string, lastName ?: string, firstName ?: string) => new Promise<void>((resolve, reject) => {
    let params: RequestParam = new RequestParam()
    params.setParam("email", email)
    if(lastName) {
        params.setParam("last_name", lastName)
    }
    if(firstName) {
        params.setParam("first_name", firstName)
    }
    ApiCall.post(`${routes.mailSubscribe}${params.toQueryString()}`, {}, {
        headers: httpHeaders(false)
    }).then(_resp => {
        resolve();
    }).catch(err => {
        reject(err);
    })
})

export const addMedia =async (data: FormData) => new Promise<Media>((resolve, reject) => {
    ApiCall.post(`${routes.media}`, data, {
        headers: httpHeaders(true, "multipart/form-data")
    }).then(resp => {
        const result = Media.map(resp.data)
        resolve(result);
    }).catch(err => {
        reject(err);
    })
})

export const loadMedias =async (page = 0, size = 25) => new Promise<PaginatedMedia>((resolve, reject) => {
    let params: RequestParam = new RequestParam()
    params.setParam("page", page.toString())
    params.setParam("size", size.toString())
    
    ApiCall.get(`${routes.media}${params.toQueryString()}`, {
        headers: httpHeaders(false)
    }).then(resp => {
        const results = new PaginatedMedia(
            resp.data.page, 
            resp.data.totalPages, 
            resp.data.medias.map((media: any) => Media.map(media)))
        resolve(results)
    }).catch(err => {
        reject(err);
    })
})

export const deleteMedia =async (mediaId: number) => new Promise<void>((resolve, reject) => {
    ApiCall.delete(`${routes.media}/${mediaId}`, {
        headers: httpHeaders()
    }).then(_resp => {
        resolve();
    }).catch(err => {
        reject(err);
    })
})

export const downloadMailingList = async () => new Promise<void>((resolve, reject) => {
    ApiCall.get('/mail/contacts/download', {
        headers: httpHeaders(),
        responseType: 'blob'
    }).then(({ data }) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', 'YSDA-MailingList-Contacts.csv');
        document.body.appendChild(link);
        link.click();
        link.remove();
        resolve();
    }).catch((_: any) => {
        reject();
    });
})