export default class RequestParam {
    params: Map<string, string>;

    constructor() {
        this.params = new Map();
    }

    setParam(key: string, value: string) {
        this.params.set(key, value);
    }

    toQueryString() {
        if(this.params.size === 0) {
            return "";
        }
        let tokens: string[] = []
        this.params.forEach((value, key) => {
            tokens.push(key + "=" + value)
        })
        return "?" + tokens.join("&")
    }
}