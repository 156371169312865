import { useFormik } from 'formik';
import React, { useState } from 'react'
import { Button, Form, InputGroup } from 'react-bootstrap'
import { 
    FaTwitter,
    FaFacebookF,
    FaLinkedinIn,
    FaYoutube,
    FaMapMarkerAlt,
    FaPhoneAlt,
    FaEnvelope
} from 'react-icons/fa'
import { Link, useLocation, useNavigate } from "react-router-dom"
import { subscribeToMailingList } from '../api';

export default function Footer() {
    const location = useLocation();
    const navigate = useNavigate();
    const [subscribing, setSubscribing] = useState(false)

    const formik = useFormik({
        initialValues: {
            email: ''
        },
        onSubmit: values => {
            if(!values.email) {
                alert("Email invalide")
                return
            }
            handleSubscibeToMailingList(values.email)
        }
    });

    async function handleSubscibeToMailingList(email: string) {
        setSubscribing(_prev => true)
        try {
            await subscribeToMailingList(email)
            formik.resetForm()
            alert("Succès")
        }
        catch(err: any) {
            console.log("Failed to subscribe to mailing list ", err)
            alert("Echec: une erreur s'est produite")
        }
        finally {
            setSubscribing(_prev => false)
        }
    }

    function goToPartners() {
        if(!location.pathname.endsWith("/")) {
            navigate("/#partners", { replace: true })
        }
    }
    
    return (
        <footer>
            <div className="container-fluid bg-dark text-white-50 px-sm-3 px-lg-5">
                <div className="row pt-5">
                    <div className="col-lg-3 col-md-6 mb-5">
                    <h5 className="text-white text-uppercase mb-2" style={{letterSpacing: 3}}>
                            A Propos de nous
                        </h5>
                        <div className="d-flex flex-column justify-content-start">
                            <ul>
                                <li>
                                    <Link to={"/team"} className="text-white-50 mb-2">Notre équipe</Link>
                                </li>
                                <li>
                                    <a onClick={_e => goToPartners()} className="text-white-50 mb-2" href="#partners">Partenaires</a>
                                </li>
                                <li>
                                    <Link to={"/annual-report"} className="text-white-50 mb-2">Rapport annuel</Link>
                                </li>
                                <li>
                                    <Link to={"/become-member"} className="text-white-50 mb-2">Devenir membre</Link>
                                </li>
                            </ul>
                        </div>
                        <h6 className="text-white text-uppercase mt-4 mb-3" style={{letterSpacing: 3}}>
                            Suivez nous
                        </h6>
                        <div className="d-flex justify-content-start">
                            <a className="btn btn-square btn-social-link" href="https://twitter.com/YSDAYSDA1">
                                <FaTwitter/>
                            </a>
                            <a className="btn btn-square btn-social-link" href="https://web.facebook.com/YSDA-1601558273407017">
                                <FaFacebookF/>
                            </a>
                            <a className="btn btn-square btn-social-link" href="https://www.linkedin.com/in/ysda-ysda-b86748253/">
                                <FaLinkedinIn/>
                            </a>
                            <a className="btn btn-square btn-social-link" href="https://www.youtube.com/channel/UCvG5kheoe3Bp87wY0q7kK5w">
                                <FaYoutube/>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 mb-5">
                        <h5 className="text-white text-uppercase mb-2" style={{letterSpacing: 3}}>
                            Nos Activités
                        </h5>
                        <div className="d-flex flex-column justify-content-start">
                            <ul>
                                <li>
                                    <Link to={"/soil"} className="text-white-50 mb-2">Sol</Link>
                                </li>
                               {/*  <li>
                                    <Link to={"/bio-agriculture"} className="text-white-50 mb-2">Agriculture biologique</Link>
                                </li> */}
                                <li>
                                    <Link to={"/climate"} className="text-white-50 mb-2">Changements climatiques</Link>
                                </li>
                                <li>
                                    <Link to={""/* "/health" */} className="text-white-50 mb-2">Santé</Link>
                                </li>
                                <li>
                                    <Link to={"/education"} className="text-white-50 mb-2">Education</Link>
                                </li>
                                <li>
                                    <Link to={"/gender-equity"} className="text-white-50 mb-2">Equité de genre</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 mb-5">
                        <h5 className="text-white text-uppercase mb-2" style={{letterSpacing: 3}}>
                            Engagez-vous
                        </h5>
                        <div className="d-flex flex-column justify-content-start">
                            <ul>
                                <li>
                                    <Link to={"/jobs"} className="text-white-50 mb-2">Devenir bénévole</Link>
                                </li>
                                <li>
                                    <Link to={"/become-mentor"} className="text-white-50 mb-2">Devenir mentor</Link>
                                </li>
                                <li>
                                    <Link to={"/hiring"} className="text-white-50 mb-2">Jobs et opportunités</Link>
                                </li>
                                <li>
                                    <Link to={"/events"} className="text-white-50 mb-2">Evènements</Link>
                                </li>
                                <li>
                                    <Link to={"/subscribe"} className="text-white-50 mb-2">Souscrire à l'actualité de YSDA</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 mb-5">
                        <h5 className="text-white text-uppercase mb-4" style={{letterSpacing: 3}}>
                            Contactez-nous
                        </h5>
                        <div className="d-flex flex-nowrap">
                            <FaMapMarkerAlt/>
                            <p className='flex-grow-1 px-2'>Auto-école Nguimatsa, Dschang</p>
                        </div>
                        <div className="d-flex flex-nowrap">
                            <FaPhoneAlt/>
                            <p className='flex-grow-1 px-2'>+237 678 010 768 / 698 363 559</p>
                        </div>
                        <div className="d-flex flex-nowrap">
                            <FaEnvelope/>
                            <p className='flex-grow-1 px-2'>contact@ysda2014.org</p>
                        </div>
                        <h6 className="text-white text-uppercase mt-4 mb-3" style={{letterSpacing: 3}}>
                            Newsletter
                        </h6>
                        <form className="w-100" onSubmit={formik.handleSubmit}>
                            <InputGroup size="lg" className="mb-3">
                                <Form.Control
                                    placeholder="Your Email"
                                    aria-label="Your Email"
                                    aria-describedby="basic-addon2"
                                    type='email'
                                    name='email' id='email'
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                />
                                <Button variant='primary' id="button-addon2" type='submit'>
                                    {!subscribing? "Souscrire" :
                                    <div className="spinner-border spinner-border-sm" role="status"></div>}
                                </Button>
                            </InputGroup>
                        </form>
                    </div>
                </div>
            </div>
            <div className="container-fluid bg-dark text-white border-top py-4 px-sm-3 px-md-5" style={{borderColor: "rgba(256, 256, 256, .1)!important"}}>
                <div className="row">
                    <div className="col-lg-6 text-center text-md-left mb-3 mb-md-0">
                        <p className="m-0 text-white-50">
                            Copyright &copy; <Link className='link-ysda' to='/'>YSDA</Link>. Tous droits réservés.
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    )
}