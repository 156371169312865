import Media from "./Media";

export default class PaginatedMedia {
    page: number
    totalPages: number
    medias: Media[]

    constructor(page: number, totalPages: number, medias: Media[]) {
        this.medias = medias
        this.totalPages = totalPages
        this.page = page
    }
}