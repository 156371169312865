import { baseUrl } from "./ApiUtils";
import MediaType from "./MediaType";

export default class Media {
    id!: number
    type!: MediaType
    title!: string
    uploadDate!: Date
    minimap!: string
    mediaLink!: string

    static map(data: any) {
        let media = new Media()
        media.id = data.id
        media.type = data.type
        media.title = data.title
        media.uploadDate = new Date(data.uploadDate)
        media.minimap = data.minimap
        media.mediaLink = data.mediaLink
        return media
    }

    get minimapUrl() {
        return `${baseUrl}/${this.minimap}`
    }

    get mediaUrl() {
        return `${baseUrl}/${this.mediaLink}`
    }

    get mediaType() {
        if(this.type === MediaType.IMAGE) {
            return "Image"
        }
        else if(this.type === MediaType.AUDIO) {
            return "Audio"
        }
        else if(this.type === MediaType.VIDEO) {
            return "Vidéo"
        }
        else if(this.type === MediaType.DOCUMENT) {
            return "Document"
        }
        else {
            return ""
        }
    }

    static toMediaType(type: string) {
        if(type === MediaType.IMAGE) {
            return MediaType.IMAGE
        }
        else if(type === MediaType.AUDIO) {
            return MediaType.AUDIO
        }
        else if(type === MediaType.VIDEO) {
            return MediaType.VIDEO
        }
        else {
            return MediaType.DOCUMENT
        }
    }
}