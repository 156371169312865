import React, { useContext, useState } from 'react'
import { ALT_MENU_ITEMS, MenuItem, MENU_ITEMS } from '../data/Menu'
import { BiMenu, BiCaretDown } from 'react-icons/bi'
import { Offcanvas, OverlayTrigger, Popover } from 'react-bootstrap';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { BsHouseFill, BsX } from 'react-icons/bs';
import AppContext from '../contexts/app.context';

type MyLinkProps = {
    menu: MenuItem, 
    className: string,
    onCkick?: () => void
}

type HeaderProps = {
    alternativeMenu?: boolean
}

function MyLink(props: MyLinkProps) {
    const location = useLocation();
    const navigate = useNavigate();

    function goTo(pathname: string) {
        if(!location.pathname.endsWith("/")) {
            navigate(pathname, { replace: true })
        }
    }

    const popover = (menu: MenuItem) => (
        <Popover id={`popover-${menu.name}`}>
          <Popover.Header as="h3" className='menu-popup-header'>{menu.submenu?.title}</Popover.Header>
          <Popover.Body>
            {menu.submenu?.items.map(it => <MyLink key={it.name} menu={it} className='nav-item-pop'/>)}
          </Popover.Body>
        </Popover>
    );

    return !!(props.menu.submenu)?
        <OverlayTrigger trigger="click" rootClose placement="left" overlay={popover(props.menu)}>
            <a 
                href="#"
                className={props.className}>{props.menu.name}<span>&nbsp;<BiCaretDown/></span></a>
        </OverlayTrigger> 
        : (props.menu.isManual)? 
        <a href={props.menu.pathname} target={props.menu.external? "_blank" : undefined} className={props.className}>
            {props.menu.name}
        </a> 
        : 
        <NavLink to={props.menu.pathname} className={(activeState: {isActive: boolean}) => `${activeState.isActive? props.className + ' active' : props.className}`} onClick={_e => {if(props.onCkick) props.onCkick()}}>
            {props.menu.name}
        </NavLink>
}

export default function Header(props: HeaderProps) {
    const appContext = useContext(AppContext);
    const [sidebarVisible, setSidebarVisible] = useState(false);

    return (
        <header className={`${!!props.alternativeMenu? 'sticky' : ''}`}>
            <nav>
                <button className='btn btn-light btn-sm nav-toggle me-3' onClick={_e => setSidebarVisible(true)}>
                    <BiMenu/>
                </button>
                <Link to='/'>
                    <img src={require("../assets/images/ysd_logo_f_sans_fond.png")} alt="YSDA"/>
                </Link>
                <div className='flex-grow-1'></div>
                {(!!props.alternativeMenu)?
                <>
                    <NavLink to="/blogposts" end={true} className={(activeState: {isActive: boolean}) => `${activeState.isActive? 'nav-item active' : 'nav-item'}`} onClick={_e => appContext.setGlobals({...appContext.globals, blogPostFilterBy: "all"})}>
                        <BsHouseFill/>
                    </NavLink>
                    {ALT_MENU_ITEMS.map((it, index) => 
                    <MyLink 
                        key={index} 
                        menu={it} 
                        className="nav-item" 
                        onCkick={() => {
                            if(it.pathname.endsWith('experiments') || it.pathname.endsWith('innovations')) {
                                appContext.setGlobals({...appContext.globals, blogPostFilterBy: it.name})
                            }
                        }}/>)}
                </>
                :
                <>
                    {MENU_ITEMS.map((it, index) => <MyLink key={index} menu={it} className="nav-item"/>)}
                </>}
            </nav>
            <Offcanvas show={sidebarVisible} onHide={() => setSidebarVisible(false)}>
                <Offcanvas.Header closeButton={false}>
                    <Offcanvas.Title>
                        <h1 className='px-3 m-0'>YS<span>DA</span></h1>
                    </Offcanvas.Title>
                    <button type='button' className='btn btn-sm btn-light' onClick={_e => setSidebarVisible(false)}>
                        <BsX/>
                    </button>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className='px-3'>
                        {(!!props.alternativeMenu)?
                        <>
                            <Link to="/blogposts" className='sidebar-nav-item color-primary' onClick={_e => appContext.setGlobals({...appContext.globals, blogPostFilterBy: "all"})}>
                                <BsHouseFill/>
                            </Link>
                            {ALT_MENU_ITEMS.map((it, index) => 
                            <MyLink 
                                key={index} 
                                menu={it} 
                                className="sidebar-nav-item" 
                                onCkick={() => {
                                    if(it.pathname.endsWith('experiments') || it.pathname.endsWith('innovations')) {
                                        appContext.setGlobals({...appContext.globals, blogPostFilterBy: it.name})
                                    }
                                }}/>)}
                        </>
                        :
                        <>
                            {MENU_ITEMS.map((it, index) => 
                                <div key={index}>
                                    {(!!it.submenu)?
                                    <>
                                        <span 
                                            key={index} 
                                            className="sidebar-nav-item">{it.name}</span>
                                        <div key={`items-${index}`} className='sub-nav'>
                                            {it.submenu.items.map((sit, idx) => <MyLink key={`${index}${idx}`} menu={sit} className="sidebar-nav-item"/>)}
                                        </div>
                                    </>
                                    :
                                    <MyLink key={index} menu={it} className="sidebar-nav-item"/>}
                                </div>
                            )}
                        </>
                        }
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </header>
    )
}