import ArticleLight from "./ArticleLight";

export default class PaginatedArticle {
    page: number
    totalPages: number
    articles: ArticleLight[]

    constructor(page: number, totalPages: number, articles: ArticleLight[]) {
        this.articles = articles
        this.totalPages = totalPages
        this.page = page
    }
}