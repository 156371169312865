import Resource from "./Resource";

export default class PaginatedResource {
    page: number
    totalPages: number
    resources: Resource[]

    constructor(page: number, totalPages: number, resources: Resource[]) {
        this.resources = resources
        this.totalPages = totalPages
        this.page = page
    }
}