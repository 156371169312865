import React from 'react'
import OwlCarousel from 'react-owl-carousel';

export default function Partners() { 
    
    const options = {
        loop: false, 
        center: false,
        autoplay: true,
        margin: 0, 
        nav: false,
        dots: true,
        rewind: true,
        autoplayHoverPause: true,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 3
            },
            992: {
                items: 5
            }
        }
    }

    return (
        <div className='w-100 d-flex align-items-center justify-content-center flex-wrap'>
            <OwlCarousel className='owl-theme carourel-partners' {...options}>
                <a href='https://www.caysti.com/' className='partner-item'>
                    <img src={require("../assets/images/LogoCAYSTI1.png")} alt="Caysti" style={{width: 100}}/>
                </a>
                <a href='https://zenu.org/' className='partner-item'>
                    <img src={require("../assets/images/Zenu-Logo-Titre-179x58.png")} alt="Zenü Network" />
                </a>
                <a href='https://apader-cam.org/' className='partner-item'>
                    <img src={require("../assets/images/Logo_2.png")} alt="Apader" />
                </a>
                <a href='https://uni2grow.org/' className='partner-item'>
                    <img src={require("../assets/images/cropped-logo-big-1.png")} alt="Uni2grow" />
                </a>
                <a href='https://european-union.europa.eu/index_fr#' className='partner-item'>
                    <svg
                        width="172px"
                        height="48px"
                        viewBox="0 0 172 48"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        >
                            <title>EU/EU-emblem/standard-version/positive/EN</title>
                            <desc>Created with Sketch.</desc>
                            <g
                            id="EU/EU-emblem/standard-version/positive/EN"
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                        >
                                <g id="EU-emblem_standard">
                                    <g id="EU/EU-emblem/flag/flag-positive">
                                        <g id="EU_flag">
                                            <rect
                                    id="🌈-flag"
                                    fill="#003399"
                                    fillRule="nonzero"
                                    x="0"
                                    y="0"
                                    width="72"
                                    height="48"
                                />
                                            <path
                                    d="M35.06,8.91 L33.54,7.8 L35.42,7.8 L36,6 L36.58,7.8 L38.46,7.8 L36.94,8.91 L37.52,10.72 L36,9.6 L34.48,10.72 L35.06,8.91 L35.06,8.91 Z M36.94,40.2 L37.52,42 L36,40.89 L34.48,42 L35.06,40.2 L33.54,39.09 L35.42,39.09 L36,37.28 L36.58,39.09 L38.46,39.09 L36.94,40.2 Z M20.46,25.2 L18.94,26.32 L19.52,24.52 L18,23.44 L19.88,23.44 L20.46,21.64 L21.04,23.44 L22.93,23.44 L21.4,24.56 L21.98,26.36 L20.46,25.24 L20.46,25.2 Z M29.17,11 L29.75,12.8 L28.23,11.7 L26.71,12.81 L27.29,11.01 L25.77,9.9 L27.65,9.9 L28.23,8.1 L28.81,9.9 L30.69,9.9 L29.17,11 Z M22,15.62 L22.58,13.82 L23.17,15.62 L25,15.62 L23.48,16.74 L24.06,18.54 L22.53,17.42 L21,18.54 L21.58,16.74 L20.06,15.62 L22,15.62 Z M20.08,31.26 L22,31.26 L22.58,29.46 L23.17,31.26 L25,31.26 L23.48,32.38 L24.06,34.18 L22.53,33.07 L21,34.18 L21.58,32.38 L20.06,31.26 L20.08,31.26 Z M27.29,38.1 L25.77,37 L27.65,37 L28.23,35.2 L28.81,37 L30.69,37 L29.17,38.1 L29.75,39.9 L28.23,38.79 L26.71,39.9 L27.29,38.1 Z M52.48,24.56 L53.06,26.36 L51.54,25.24 L50,26.36 L50.58,24.56 L49.05,23.44 L51,23.44 L51.58,21.64 L52.16,23.44 L54,23.44 L52.48,24.56 Z M42.83,11 L41.31,9.9 L43.19,9.9 L43.77,8.1 L44.35,9.9 L46.23,9.9 L44.71,11 L45.29,12.8 L43.77,11.7 L42.25,12.81 L42.83,11.01 L42.83,11 Z M48.83,15.61 L49.42,13.81 L50,15.61 L51.88,15.61 L50.4,16.74 L50.98,18.54 L49.46,17.42 L47.93,18.54 L48.51,16.74 L47,15.62 L48.83,15.61 Z M50,31.26 L51.88,31.26 L50.4,32.38 L50.98,34.18 L49.46,33.07 L47.93,34.18 L48.51,32.38 L47,31.26 L48.88,31.26 L49.47,29.46 L50.05,31.26 L50,31.26 Z M46.23,37 L44.71,38.1 L45.29,39.9 L43.77,38.79 L42.25,39.9 L42.83,38.1 L41.31,37 L43.19,37 L43.77,35.2 L44.35,37 L46.23,37 Z"
                                    id="🌈-stars"
                                    fill="#FFCC00"
                                    fillRule="nonzero"
                                />
                                        </g>
                                    </g>
                                    <g
                                id="🌈-Union"
                                transform="translate(83.000000, 27.000000)"
                                fill="#515560"
                                fillRule="nonzero"
                            >
                                        <path
                                d="M1,0.69 L3.89,0.69 L3.89,8.44 C3.8678325,9.24161441 3.90459816,10.0437742 4,10.84 C4.11178312,11.401504 4.42470326,11.9028874 4.88,12.25 C5.44212954,12.6295873 6.11264827,12.8156474 6.79,12.78 C7.44810314,12.8222963 8.10180702,12.6465695 8.65,12.28 C9.05776827,11.9646946 9.33251362,11.507975 9.42,11 C9.51798477,10.1939055 9.55808745,9.38182642 9.54,8.57 L9.54,0.69 L12.44,0.69 L12.44,8.21 C12.477931,9.42824013 12.3975543,10.6472864 12.2,11.85 C12.0666927,12.5119183 11.7711935,13.130405 11.34,13.65 C10.8769401,14.1679576 10.2978054,14.568897 9.65,14.82 C8.76813501,15.1334363 7.8354524,15.2792739 6.9,15.25 C5.84244573,15.3006748 4.78535167,15.1409211 3.79,14.78 C3.14209323,14.5060045 2.56690093,14.0848815 2.11,13.55 C1.72897377,13.0994058 1.4523631,12.5700891 1.3,12 C1.06994935,10.7907922 0.969381804,9.5605159 1,8.33 L1,0.69 Z"
                                id="🌈-U"
                                />
                                        <path
                                d="M24.3,15 L21.55,15 L21.55,9.7 C21.5855773,8.97238442 21.5284521,8.24319853 21.38,7.53 C21.2731042,7.22114019 21.0741851,6.95242486 20.81,6.76 C20.5131439,6.56754971 20.1633772,6.47311271 19.81,6.49 C19.3533771,6.48525986 18.906401,6.62144789 18.53,6.88 C18.1721289,7.12148884 17.9020074,7.4722961 17.76,7.88 C17.5835877,8.66032644 17.5129543,9.46083903 17.55,10.26 L17.55,15 L14.84,15 L14.84,4.63 L17.39,4.63 L17.39,6.16 C18.175208,5.05121788 19.451354,4.39448775 20.81,4.4 C21.3803442,4.39246359 21.945904,4.50489415 22.47,4.73 C22.9143712,4.90527174 23.3067253,5.19093308 23.61,5.56 C23.8619724,5.90392796 24.0422117,6.29501327 24.14,6.71 C24.2649634,7.3183654 24.3186584,7.93921326 24.3,8.56 L24.3,15 Z"
                                id="🌈-n"
                                />
                                        <path
                                d="M26.58,3.23 L26.58,0.69 L29.32,0.69 L29.32,3.23 L26.58,3.23 Z M26.58,15.01 L26.58,4.63 L29.32,4.63 L29.32,15 L26.58,15.01 Z"
                                id="🌈-i"
                                />
                                        <path
                                d="M31,9.67 C31.0049073,8.74731313 31.2385465,7.84024316 31.68,7.03 C32.091107,6.18277843 32.7549603,5.48398545 33.58,5.03 C34.4304055,4.58177682 35.3787392,4.35156536 36.34,4.36 C37.7837214,4.309447 39.1829673,4.86407035 40.2,5.89 C41.20477,6.91872288 41.7464925,8.31275543 41.7,9.75 C41.7478306,11.2012282 41.2026306,12.6093607 40.19,13.65 C39.1849397,14.6775563 37.7968269,15.2393251 36.36,15.2 C35.4156003,15.2037053 34.483663,14.9844259 33.64,14.56 C32.7872002,14.1618112 32.0857443,13.4989353 31.64,12.67 C31.1823592,11.7381398 30.9624878,10.7074928 31,9.67 L31,9.67 Z M33.81,9.82 C33.7526345,10.6715533 34.016685,11.5136601 34.55,12.18 C35.0038579,12.6995884 35.6601021,12.9977087 36.35,12.9977087 C37.0398979,12.9977087 37.6961421,12.6995884 38.15,12.18 C38.6819239,11.5056213 38.9423233,10.6566478 38.88,9.8 C38.9380359,8.95613492 38.6775584,8.12117969 38.15,7.46 C37.6961421,6.94041163 37.0398979,6.64229132 36.35,6.64229132 C35.6601021,6.64229132 35.0038579,6.94041163 34.55,7.46 C34.016685,8.12633988 33.7526345,8.96844673 33.81,9.82 L33.81,9.82 Z"
                                id="🌈-o"
                                />
                                        <path
                                d="M52.79,15 L50,15 L50,9.7 C50.0355773,8.97238442 49.9784521,8.24319853 49.83,7.53 C49.7231042,7.22114019 49.5241851,6.95242486 49.26,6.76 C48.9631439,6.56754971 48.6133772,6.47311271 48.26,6.49 C47.8033771,6.48525986 47.356401,6.62144789 46.98,6.88 C46.6221289,7.12148884 46.3520074,7.4722961 46.21,7.88 C46.0335877,8.66032644 45.9629543,9.46083903 46,10.26 L46,15 L43.25,15 L43.25,4.63 L45.8,4.63 L45.8,6.16 C46.585208,5.05121788 47.861354,4.39448775 49.22,4.4 C49.7903442,4.39246359 50.355904,4.50489415 50.88,4.73 C51.3243712,4.90527174 51.7167253,5.19093308 52.02,5.56 C52.271073,5.90575398 52.4543094,6.2959795 52.56,6.71 C52.6758254,7.31955361 52.7261115,7.9397488 52.71,8.56 L52.79,15 Z"
                                id="🌈-n"
                                />
                                    </g>
                                    <g
                                id="🌈-European"
                                transform="translate(84.000000, 6.000000)"
                                fill="#515560"
                                fillRule="nonzero"
                            >
                                        <polygon
                                id="🌈-E"
                                points="0 14.33 0 0 10.65 0 10.65 2.43 2.92 2.43 2.92 5.61 10.11 5.61 10.11 8 2.92 8 2.92 11.89 10.92 11.89 10.92 14.31"
                                />
                                        <path
                                d="M19.9,14.33 L19.9,12.77 C19.5212252,13.3248647 19.0087967,13.7753891 18.41,14.08 C17.7934196,14.4032876 17.1061455,14.5682334 16.41,14.56 C15.7540832,14.5719591 15.1062936,14.4134574 14.53,14.1 C13.9960496,13.8175266 13.5731879,13.3629502 13.33,12.81 C13.069166,12.0761187 12.9569874,11.297667 13,10.52 L13,4 L15.75,4 L15.75,8.77 C15.7096323,9.66610518 15.7598874,10.5639963 15.9,11.45 C15.9878498,11.7656691 16.182165,12.0412435 16.45,12.23 C16.7450842,12.4282653 17.0946255,12.5296323 17.45,12.52 C17.8972809,12.5244758 18.3346197,12.3880261 18.7,12.13 C19.0538421,11.9062755 19.3243805,11.5724944 19.47,11.18 C19.64967,10.2581912 19.7168274,9.31798719 19.67,8.38 L19.67,4 L22.42,4 L22.42,14.33 L19.9,14.33 Z"
                                id="🌈-u"
                                />
                                        <path
                                d="M27.61,14.33 L24.87,14.33 L24.87,4 L27.42,4 L27.42,5.48 C27.7135943,4.94329671 28.1154043,4.47338325 28.6,4.1 C28.9527623,3.87734116 29.36291,3.76263884 29.78,3.77 C30.4199569,3.77159768 31.046781,3.95167974 31.59,4.29 L30.74,6.68 C30.3629472,6.40767318 29.9146265,6.25128222 29.45,6.23 C29.0897054,6.21791863 28.7363465,6.33099349 28.45,6.55 C28.129991,6.86191047 27.9105885,7.26240699 27.82,7.7 C27.6207931,8.84527309 27.5436946,10.0084538 27.59,11.17 L27.61,14.33 Z"
                                id="🌈-r"
                                />
                                        <path
                                d="M31.84,9 C31.8414197,8.07811408 32.0716356,7.17099467 32.51,6.36 C32.9280484,5.51431914 33.5944498,4.81651666 34.42,4.36 C35.2677843,3.90488647 36.2179095,3.67424013 37.18,3.69 C38.6142068,3.65972579 39.9969803,4.22441866 41,5.25 C42.00477,6.27872288 42.5464925,7.67275543 42.5,9.11 C42.5465961,10.5561552 42.0054531,11.9595193 41,13 C39.9987129,14.0270347 38.6138263,14.5889651 37.18,14.55 C36.2356003,14.5537053 35.303663,14.3344259 34.46,13.91 C33.6103978,13.5071219 32.9102454,12.8454778 32.46,12.02 C32.0064089,11.0802169 31.7933633,10.0424789 31.84,9 Z M34.65,9.15 C34.5888366,10.0003686 34.8493827,10.8426822 35.38,11.51 C35.835708,12.0300228 36.4935569,12.3282367 37.185,12.3282367 C37.8764431,12.3282367 38.534292,12.0300228 38.99,11.51 C39.5219239,10.8356213 39.7823233,9.98664779 39.72,9.13 C39.7780359,8.28613492 39.5175584,7.45117969 38.99,6.79 C38.534292,6.26997718 37.8764431,5.97176326 37.185,5.97176326 C36.4935569,5.97176326 35.835708,6.26997718 35.38,6.79 C34.8517875,7.45471144 34.5913838,8.2929971 34.65,9.14 L34.65,9.15 Z"
                                id="🌈-o"
                                />
                                        <path
                                d="M44.27,4 L46.83,4 L46.83,5.53 C47.1666441,5.00037466 47.6308221,4.56370354 48.18,4.26 C48.7506994,3.92935657 49.4005133,3.75999019 50.06,3.77 C51.244791,3.75764244 52.3722381,4.2791326 53.13,5.19 C54.0268077,6.30016135 54.4762208,7.70546914 54.39,9.13 C54.461981,10.5727408 54.0110426,11.9930192 53.12,13.13 C52.3477253,14.0512902 51.2020462,14.5763931 50,14.56 C49.4607497,14.5683706 48.9268596,14.4520099 48.44,14.22 C47.8973158,13.9142006 47.4104143,13.5185931 47,13.05 L47,18.27 L44.26,18.27 L44.27,4 Z M47,9 C46.9282091,9.91817467 47.1754582,10.8329963 47.7,11.59 C48.1028362,12.1153665 48.7279719,12.4223858 49.39,12.42 C50.0090618,12.4264112 50.5961943,12.1457693 50.98,11.66 C51.4644905,10.9134742 51.6861854,10.0266948 51.61,9.14 C51.6797113,8.28243771 51.4500055,7.42722515 50.96,6.72 C50.5759841,6.21448245 49.974776,5.92130072 49.34,5.93 C48.6951929,5.91930312 48.080576,6.20268935 47.67,6.7 C47.1822932,7.36360245 46.9449695,8.17829582 47,9 L47,9 Z"
                                id="🌈-p"
                                />
                                        <path
                                d="M62.37,11 L65.1,11.45 C64.8130968,12.3748843 64.2297486,13.1796238 63.44,13.74 C62.5951369,14.2930105 61.599001,14.5691324 60.59,14.53 C59.0447651,14.6448985 57.5441538,13.980878 56.59,12.76 C55.8843274,11.7071641 55.5339205,10.4562118 55.59,9.19 C55.5135026,7.7333052 55.9964261,6.30242072 56.94,5.19 C57.8107713,4.23131045 59.0552666,3.69847669 60.35,3.73 C61.7334648,3.66127261 63.0742438,4.21962444 64,5.25 C64.9902179,6.59828149 65.4461916,8.26543557 65.28,9.93 L58.41,9.93 C58.3851457,10.6235106 58.620568,11.3012415 59.07,11.83 C59.4738823,12.2740211 60.0498887,12.5219226 60.65,12.51 C61.0424109,12.5196203 61.4265461,12.3962741 61.74,12.16 C62.0632712,11.8465439 62.2830789,11.4418186 62.37,11 L62.37,11 Z M62.53,8.22 C62.5559403,7.56051029 62.3348519,6.91507469 61.91,6.41 C61.5470264,6.00989135 61.0301884,5.78422968 60.49,5.79 C59.9218585,5.77673139 59.3767464,6.01453197 59,6.44 C58.5897686,6.93883036 58.3858393,7.57566205 58.43,8.22 L62.53,8.22 Z"
                                id="🌈-e"
                                />
                                        <path
                                d="M69.54,7.12 L67.05,6.67 C67.2544769,5.78115376 67.7640103,4.99208462 68.49,4.44 C69.3969292,3.90894172 70.440834,3.65840457 71.49,3.72 C72.4199943,3.67205521 73.3497712,3.81850358 74.22,4.15 C74.7506314,4.37548354 75.195521,4.76432407 75.49,5.26 C75.7927699,6.03931612 75.9191307,6.87602952 75.86,7.71 L75.86,10.92 C75.8416494,11.5892475 75.8851677,12.2587593 75.99,12.92 C76.0947998,13.4051371 76.2593905,13.8753963 76.48,14.32 L73.77,14.32 C73.7033333,14.1333333 73.6133333,13.8633333 73.5,13.51 C73.5,13.34 73.42,13.24 73.41,13.18 C72.9733242,13.6044481 72.4612091,13.9435972 71.9,14.18 C71.3596209,14.4102871 70.7773713,14.526056 70.19,14.52 C69.2682718,14.5727029 68.3628925,14.2601315 67.67,13.65 C66.6665342,12.6567707 66.4609735,11.1109543 67.17,9.89 C67.4502518,9.43175528 67.8563988,9.06379307 68.34,8.83 C69.0379492,8.52884338 69.7700192,8.31392374 70.52,8.19 C71.4291269,8.05131417 72.3231049,7.8269826 73.19,7.52 L73.19,7.24 C73.2309608,6.8274882 73.0883195,6.41785168 72.8,6.12 C72.3624767,5.84386329 71.8443389,5.72402189 71.33,5.78 C70.9261627,5.75817053 70.5251954,5.85928401 70.18,6.07 C69.8616938,6.34410523 69.6377734,6.71147477 69.54,7.12 L69.54,7.12 Z M73.21,9.35 C72.86,9.46 72.3,9.6 71.54,9.77 C71.0222854,9.84982623 70.5198155,10.0083235 70.05,10.24 C69.7078564,10.4562881 69.5031996,10.8352822 69.51,11.24 C69.5066394,11.6189085 69.6626766,11.9817856 69.94,12.24 C70.2316557,12.5215326 70.6248411,12.6730352 71.03,12.66 C71.5433265,12.6514267 72.040626,12.4798234 72.45,12.17 C72.762533,11.9480897 72.9914469,11.6276103 73.1,11.26 C73.1919353,10.8196003 73.2288841,10.3694968 73.21,9.92 L73.21,9.35 Z"
                                id="🌈-a"
                                />
                                        <path
                                d="M87.92,14.33 L85.18,14.33 L85.18,9 C85.2191795,8.2718436 85.1586187,7.54174919 85,6.83 C84.9006237,6.51723671 84.7001142,6.24637292 84.43,6.06 C84.1345873,5.86433404 83.7837485,5.76960756 83.43,5.79 C82.9733771,5.78525986 82.526401,5.92144789 82.15,6.18 C81.7868865,6.41585827 81.5152239,6.76866673 81.38,7.18 C81.2035877,7.96032644 81.1329543,8.76083903 81.17,9.56 L81.17,14.26 L78.42,14.26 L78.42,4 L81,4 L81,5.53 C81.7840613,4.41989792 83.0609464,3.76278746 84.42,3.77 C84.9902623,3.76335163 85.5556399,3.87574597 86.08,4.1 C86.5243712,4.27527174 86.9167253,4.56093308 87.22,4.93 C87.4719724,5.27392796 87.6522117,5.66501327 87.75,6.08 C87.8658254,6.68955361 87.9161115,7.3097488 87.9,7.93 L87.92,14.33 Z"
                                id="🌈-n"
                                />
                                    </g>
                                </g>
                            </g>
                        </svg>
                </a>
                <a href='https://activspaces.com/' className='partner-item'>
                    <img src={require("../assets/images/activ-space-logo.jpg")} alt="Uni2grow" />
                </a>
            </OwlCarousel>
        </div>
    )
}