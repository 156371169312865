import React, { Suspense, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { isAuthenticated, loadCategories, loadTags } from './api';
import Category from './api/Category';
import Tag from './api/Tag';
import AppContext, { AppContextModel, AppGlobals, defaultContext } from './contexts/app.context';
import Home from './pages/Home';
import LoadingIndicator from './components/LoadingIndicator';
import NotFound from './pages/NotFound';

const Hiring = React.lazy(() => import('./pages/Hiring'));
const GetInvolved = React.lazy(() => import('./pages/GetInvolved'));
const Jobs = React.lazy(() => import('./pages/Jobs'));
const BlogPostDetail = React.lazy(() => import('./pages/BlogPostDetail'));
const BlogPosts = React.lazy(() => import('./pages/BlogPosts'));
const NewsDetail = React.lazy(() => import('./pages/NewsDetail'));
const NewsList = React.lazy(() => import('./pages/NewsList'));
const BecomeMember = React.lazy(() => import('./pages/BecomeMember'));
const EventDetail = React.lazy(() => import('./pages/EventDetail'));
const EventsList = React.lazy(() => import('./pages/EventsList'));
const Resources = React.lazy(() => import('./pages/Resources'));
const AnnualReport = React.lazy(() => import('./pages/AnnualReport'));
const ProjectsList = React.lazy(() => import('./pages/ProjectsList'));
const ProjectDetail = React.lazy(() => import('./pages/ProjectDetail'));
const MosaliVoices = React.lazy(() => import('./pages/MosaliVoices'));
const BecomeMentor = React.lazy(() => import('./pages/BecomeMentor'));
const SubscribeToNews = React.lazy(() => import('./pages/SubscribeToNews'));
const BiologicalAgriculture = React.lazy(() => import('./pages/BiologicalAgriculture'));
const GenderEquity = React.lazy(() => import('./pages/GenderEquity'));
const Education = React.lazy(() => import('./pages/Education'));
const Health = React.lazy(() => import('./pages/Health'));
const Sol = React.lazy(() => import('./pages/Sol'));
const ChangementClimatique = React.lazy(() => import('./pages/ChangementClimatique'));
const Team = React.lazy(() => import('./pages/Team'));
const Admin = React.lazy(() => import('./pages/Admin'));
const Login = React.lazy(() => import('./pages/Login'));

function App() {
  const [appContext, setAppContext] = useState<AppContextModel>({...defaultContext, setGlobals: setGlobals, setTags: setTags, setCategories: setCategories})
  const location = useLocation()
  
  function setGlobals(args: AppGlobals) {
    setAppContext(prev => ({
      ...prev,
      globals: args
    }))
  }
  function setTags(args: Tag[]) {
    setAppContext(prev => ({
      ...prev,
      tags: args
    }))
  }
  function setCategories(args: Category[]) {
    setAppContext(prev => ({
      ...prev,
      categories: args
    }))
  }

  async function getTags() {
    try {
      const results = await loadTags();
      setTags(results);
    }
    catch(err: any) {
      console.log("Fail to load tags", err)
    }
  }

  async function getCategories() {
    try {
      const results = await loadCategories();
      setCategories(results);
    }
    catch(err: any) {
      console.log("Fail to load categories", err)
    }
  }

  function getPageTitleFromRoute(pathname: string) {
    if(pathname.startsWith("/bio-agriculture")) {
      return "YSDA - Agriculture biologique";
    }
    else if(pathname.startsWith("/health")) {
      return "YSDA - Santé";
    }
    else if(pathname.startsWith("/education")) {
      return "YSDA - Education";
    }
    else if(pathname.startsWith("/gender-equity")) {
      return "YSDA - Equité de genre";
    }
    else if(pathname.startsWith("/projects")) {
      return "Projets";
    }
    else if(pathname.startsWith("/mosalievoices")) {
      return "MosalieVoices";
    }
    else if(pathname.startsWith("/become-mentor")) {
      return "YSDA - Devenir mentor";
    }
    else if(pathname.startsWith("/subscribe")) {
      return "YSDA - Newsletter";
    }
    else if(pathname.startsWith("/become-member")) {
      return "Devenir membre";
    }
    else if(pathname.startsWith("/jobs")) {
      return "Devenir bénévole";
    }
    else if(pathname.startsWith("/events")) {
      return "Evènements";
    }
    else if(pathname.startsWith("/team")) {
      return "Equipe";
    }
    else if(pathname.startsWith("/hiring")) {
      return "Jobs et opportunités";
    }
    else if(pathname.startsWith("/resources")) {
      return "Resources";
    }
    else if(pathname.startsWith("/blogposts")) {
      return "YSDA - Blog";
    }
    else if(pathname.startsWith("/news")) {
      return "News";
    }
    else if(pathname.startsWith("/admin")) {
      return "YSDA - Administration";
    }
    else if(pathname.startsWith("/login")) {
      return "YSDA - Login";
    }
    
    return "YSDA";
  }

  useEffect(() => {
    document.title = getPageTitleFromRoute(location.pathname)
  }, [location])

  useEffect(() => {
    getTags()
    getCategories()
    // eslint-disable-next-line
  }, [])

  return (
    <AppContext.Provider value={appContext}> 
      <Suspense fallback={<LoadingIndicator/>}>
        <Routes>
          <Route path="/" element={<Home />}/>
          <Route path="/bio-agriculture" element={<BiologicalAgriculture/>}/>
          <Route path="/health" element={<Health/>}/>
          <Route path="/education" element={<Education/>}/>
          <Route path="/gender-equity" element={<GenderEquity/>}/>
          <Route path="/soil" element={<Sol/>}/>
          <Route path="/climate" element={<ChangementClimatique/>}/>
          <Route path="/team" element={<Team/>}/>
          <Route path="/annual-report" element={<AnnualReport/>}/>
          <Route path="/projects" element={<ProjectsList/>}/>
          <Route path='/projects/:id' element={<ProjectDetail/>}/>
          <Route path='/mosalievoices' element={<MosaliVoices/>}/>
          <Route path='/become-mentor' element={<BecomeMentor/>}/>
          <Route path='/subscribe' element={<SubscribeToNews/>}/>
          <Route path='/become-member' element={<BecomeMember/>}/>
          <Route path="/events" element={<EventsList/>}/>
          <Route path='/events/:id' element={<EventDetail/>}/>
          <Route path='/jobs' element={<Jobs/>}/>
          <Route path='/hiring' element={<Hiring/>}/>
          <Route path='/resources' element={<Resources/>}/>
          <Route path="/blogposts" element={<BlogPosts/>}/>
          <Route path='/blogposts/:id' element={<BlogPostDetail/>}/>
          <Route path="/blogposts/experiments" element={<BlogPosts/>}/>
          <Route path="/blogposts/innovations" element={<BlogPosts/>}/>
          <Route path='/blogposts/get-involved' element={<GetInvolved/>}/>
          <Route path="/news" element={<NewsList/>}/>
          <Route path='/news/:id' element={<NewsDetail/>}/>
          <Route path='/admin/*' element={isAuthenticated()? <Admin/> : <Navigate to='/login' replace={true}/>}/>
          <Route path='/login' element={<Login/>}/>
          <Route path='*' element={<NotFound/>}/>
        </Routes>
      </Suspense>
    </AppContext.Provider>
  );
}

export default App;
