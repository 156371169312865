const HOST = "api.ysda2014.org"; // prod
//const HOST = "localhost"; // dev
//const PORT = 8080; // dev
const CONTEXT_PATH = "ysda-api"

export const baseUrl = `https://${HOST}/${CONTEXT_PATH}`; // prod
//export const baseUrl = `http://${HOST}:${PORT}/${CONTEXT_PATH}`; // dev

export const WEB_BASE_URL = 'https://ysda2014.org' // prod
//export const WEB_BASE_URL = baseUrl // dev

export const BasicAuth = {
    user: 'admin',
    pswd: 'adminYSDA2022'
}

export const routes = {
    tags: "/tags",
    categories: "/categories",
    articles: "/articles",
    comments: "/comments",
    resources: "/resources",
    mailSend: "/mail/send",
    mailSubscribe: "/mail/subscribe",
    media: "/media"
}