import React from 'react'
import CountUp from 'react-countup'

type Props = {
    counterOn: boolean
}

export default function Counters(props: Props) {
    const items = [
        {name: "CECIN-AFLATOON Créés", value: 90},
        {name: "Jeunes et élèves encadrés", value: 5000},
        {name: "Femmes formées", value: 55},
        {name: "Femmes mentorées", value: 25}
    ]
    return (
        <>
            {items.map(it => 
                <div key={it.name} className='text-center px-3 py-4'>
                    <h1 className='m-0'>
                        {props.counterOn? <CountUp delay={0} start={1} end={it.value} />: it.value}+
                    </h1>
                    <span>{it.name}</span>
                </div>
            )}
        </>
    )
}