export default class Comment {
    id!: number
    content!: string
    authorDisplayName!: string
    authorEmail!: string
    commentDate!: Date

    static map(data: any) {
        let comment = new Comment();
        comment.id = data.id
        comment.content = data.content
        comment.authorDisplayName = data.authorDisplayName
        comment.authorEmail = data.authorEmail
        comment.commentDate = new Date(data.commentDate)
        return comment
    }
}