import React from 'react'
import Category from '../api/Category'
import Tag from '../api/Tag'

export type AppGlobals = {
    countersTriggered: boolean, 
    blogPostFilterBy: string,
    tagFilterBy: string
}

export type AppContextModel = {
    globals: AppGlobals
    tags: Tag[]
    categories: Category[]
    setGlobals: (data: AppGlobals) => void
    setTags: (args: Tag[]) => void
    setCategories: (args: Category[]) => void
}

export const defaultContext: AppContextModel = {
    globals: {
        countersTriggered: false,
        blogPostFilterBy: "",
        tagFilterBy: ""
    },
    tags: [],
    categories: [],
    setGlobals: (data: AppGlobals) => {},
    setTags: (_args: Tag[]) => {},
    setCategories: (_args: Category[]) => {}
}


const AppContext = React.createContext(defaultContext)

export default AppContext