import React from 'react'
import { Link } from 'react-router-dom';

type Props = {
    image: string;
    title: string;
    description: string;
    action: string;
    actionCommand: string;
    btnClass: string;
    href?: string;
}

export default function CategoryItem(props: Props) {

    return (
        <div className="position-relative" style={{height: 450}}>
            <img className="img-fluid mx-auto w-100 h-100" src={props.image} alt={props.title}/>
            <div className="category-text bg-white-light p-3 mt-n5 w-100">
                <h4 className="text-truncate" style={{fontWeight: 700}}>{props.title}</h4>
                <p className="mt-3" style={{height: 50, fontWeight: 500, fontSize: 18}}>
                    {props.description}
                </p>
                {!!props.href?
                <a href={props.href} className={props.btnClass} style={{borderRadius: 3}}>
                    {props.action}
                </a>
                :
                <Link to={props.actionCommand} className={props.btnClass} style={{borderRadius: 3}}>
                    {props.action}
                </Link>}
            </div>
        </div>
    )
}