import { baseUrl } from "./ApiUtils";
import ResourceLanguage from "./ResourceLanguage";
import MediaType from "./MediaType";

export default class Resource {
    id!: number;
    type!: MediaType;
    title!: string;
    category!: string;
    topic!: string;
    language!: ResourceLanguage;
    uploadDate!: Date;
    private coverImage!: string;
    private resourceLink!: string;

    static map(data: any) {
        let resource = new Resource()
        resource.id = data.id
        resource.type = data.type
        resource.title = data.title
        resource.category = data.category
        resource.topic = data.topic
        resource.language = data.language
        resource.uploadDate = new Date(data.uploadDate)
        resource.coverImage = data.coverImage
        resource.resourceLink = data.resourceLink
        return resource
    }

    get coverUrl() {
        return `${baseUrl}/${this.coverImage}`
    }

    get resourceUrl() {
        return `${baseUrl}/${this.resourceLink}`
    }

    get resourceType() {
        if(this.type === MediaType.IMAGE) {
            return "Image"
        }
        else if(this.type === MediaType.AUDIO) {
            return "Audio"
        }
        else if(this.type === MediaType.VIDEO) {
            return "Vidéo"
        }
        else if(this.type === MediaType.DOCUMENT) {
            return "Document"
        }
        else {
            return ""
        }
    }

    static toMediaType(type: string) {
        if(type === MediaType.IMAGE) {
            return MediaType.IMAGE
        }
        else if(type === MediaType.AUDIO) {
            return MediaType.AUDIO
        }
        else if(type === MediaType.VIDEO) {
            return MediaType.VIDEO
        }
        else {
            return MediaType.DOCUMENT
        }
    }

    static toResourceLanguage(lang: string) {
        if(lang === ResourceLanguage.EN) {
            return ResourceLanguage.EN
        } else {
            return ResourceLanguage.FR
        }
    }
}