import React from 'react'
import { Link } from 'react-router-dom'

export default function Projets() {

    return (
        <div className='px-5 pt-5' style={{backgroundColor: '#fff'}}>
            <div className="row">
                <div className="col-lg-6 col-md-12 d-flex justify-content-center">
                    <img src={require('../assets/images/IMG-3239.jpg')} className="project-img" alt="Projets"/>
                </div>
                <div className="col-lg-6 col-md-12 position-relative d-flex align-items-center">
                    <div className='projects-text p-5' style={{backgroundColor: '#F3F3F3'}}>
                        <div className="pb-3">
                            <h6 className="text-primary text-uppercase">Projets</h6>
                            <h1>Découvrez nos projets</h1>
                        </div>
                        <p className='text-secondary'>
                            Depuis sa création en 2014, YSDA a mis en œuvre divers  projets au Cameroun. Ses activités sont portées vers les femmes et les jeunes défini.e.s comme cibles prioritaires de nos actions.
                        </p>
                        <div className="d-flex py-3 pb-5 flex-wrap">
                            <img src={require("../assets/images/143A9339.JPG")} style={{width: 150, margin: 8}} alt="Projet 1"/>
                            <img src={require("../assets/images/143A9470.JPG")} style={{width: 150, margin: 8}} alt="Projet 2"/>
                            <img src={require("../assets/images/143A1926.JPG")} style={{width: 150, margin: 8}} alt="Projet 3"/>
                        </div>
                        <Link to="/projects" className='btn ysda-btn-primary'>EN SAVOIR PLUS</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}