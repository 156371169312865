import React , { useEffect, useRef } from 'react'
import ScrollTrigger from 'react-scroll-trigger'
import Header from '../components/Header'
import Footer from '../components/Footer'
import $ from 'jquery'

export default function PageTemplate(props) {
    const headerRef = useRef(null)

    const scrollToTop = () => headerRef.current.scrollIntoView()

    function setStickyHeader(add = true) {
        const header = $('header');
        if(!add) {
            header.removeClass('sticky');
        } else {
            header.addClass('sticky');
        }
    }

    useEffect(() => {
        scrollToTop()
    })

    return (
        <div className='w-100'>
            <div className='header-secondary' ref={headerRef}>
                <>    
                    {(!props.isBlog)&&
                    <ScrollTrigger onExit={_args => setStickyHeader()} onEnter={_args => setStickyHeader(false)}>
                        <img src={props.Cover || require('../assets/images/banner-hs.jpg')} className='banner' alt="Banner" />
                    </ScrollTrigger>}
                    <Header alternativeMenu={!!props.isBlog? true : false}/>
                </>
            </div>
            {/* Breadcrumb */}
            {props.Breadcrumb}

            <section className='container main-content py-4' style={!!props.isBlog? {marginTop: 85} : {}}>
                <div className="row">
                    <div className="col-12">
                        {/* Content */}
                        {props.children}
                        {/* Related articles */}
                        {!!props.showRelatedArticles &&
                        <>
                            <div className="d-flex align-items-center pt-4 pb-2">
                                <h4 className='flex-grow-1 m-0'>Articles associés</h4>
                                {/* <a href="#" style={{textDecoration: 'none'}}>Explorer d'avantage <BsArrowRight/> </a> */}
                            </div>
                            {/* <div className="row pb-4">
                                <div className="col-lg-4 col-md-6 col-xs-12 py-2">
                                    <GenericCard
                                        image={require('../assets/images/img-project-3.jpg')}
                                        subtitle="Lorem ipsum dolor"
                                        title="Lorem ipsum dolor sit amet consectetur adipisicing elit."
                                        targetLink=''/>
                                </div>
                                <div className="col-lg-4 col-md-6 col-xs-12 py-2">
                                    <GenericCard
                                        image={require('../assets/images/img-project-3.jpg')}
                                        subtitle="Lorem ipsum dolor"
                                        title="Lorem ipsum dolor sit amet consectetur adipisicing elit."
                                        targetLink=''/>
                                </div>
                                <div className="col-lg-4 col-md-6 col-xs-12 py-2">
                                    <GenericCard
                                        image={require('../assets/images/img-project-3.jpg')}
                                        subtitle="Lorem ipsum dolor"
                                        title="Lorem ipsum dolor sit amet consectetur adipisicing elit."
                                        targetLink=''/>
                                </div>
                            </div> */}
                        </>}
                    </div>
                </div>
            </section>
            <Footer/>
        </div>
    )
}