import React from "react";
import Carousel from "react-bootstrap/Carousel";
import { Link } from 'react-router-dom'

export default function Slider() {
  return (
    <Carousel fade pause={false} interval={15000}>
      <Carousel.Item>
        <img
          className="slider-img"
          src={require("../assets/images/IMG_1161.jpg")}
          alt="First slide"
        />
        <Carousel.Caption>
          <div className="my-caption">
            <h3 className="fw-bold">MosalieVoices</h3>
            <p>
              MosaliVoices est un réseau féministe qui vise à connecter et à renforcer les mouvements féministes, à s'engager directement sur des questions critiques et à construire un pouvoir collectif et une solidarité à travers le monde.
            </p>
            <Link to='/mosalievoices' className="btn ysda-btn-animate green mybtn-carousel">
              Rejoignez-nous
            </Link>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="slider-img"
          src={require("../assets/images/wicode_2-slider.jpg")}
          alt="Wicode"
        />

        <Carousel.Caption>
          <div className="my-caption transparent">
            {/* <h3 className="fw-bold">Wicode</h3>
            <p>
              Vous êtes un.e jeune, passionné.e de la tech, vous désirez renforcer vos connaissances dans le domaine de la programmation et de la data Science? Saisissez cette opportunité de formation qui s'offre à vous.
            </p> */}
            <Link to='/' className="btn ysda-btn-animate orange mybtn-carousel">
              Postulez
            </Link>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      {/* <Carousel.Item>
        <img
          className="slider-img"
          src={require("../assets/images/DSC_0496.jpg")}
          alt="Third slide"
        />
        <Carousel.Caption>
          <div className="my-caption">
            <h3 className="fw-bold">MosalieVoices</h3>
            <p>
              MosaliVoices est un réseau féministe qui vise à connecter et à renforcer les mouvements féministes, à s'engager directement sur des questions critiques et à construire un pouvoir collectif et une solidarité à travers le monde.
            </p>
            <Link to='/mosalievoices' className="header-btn-link">
              Rejoignez-nous
            </Link>
          </div>
        </Carousel.Caption>
      </Carousel.Item> */}
    </Carousel>
  );
}
