import React, { useEffect, useState } from 'react';

const MAX_VALUE = 85;
export default function LoadingIndicator() {
    const [currentValue, setCurrentValue] = useState(55);

    useEffect(() => {
        const timer = setInterval(() => {
            let value = currentValue;
            if(value < MAX_VALUE) {
                value+= 10;
            }
            setCurrentValue(value);
        }, 100);

        return () => {
            if(timer) {
                clearInterval(timer);
            }
        }
    }, [])

    return (
        <div className='w-100 py-5 d-flex flex-column justify-content-center align-items-center'>
            <div className="elevate p-3" style={{width: 350}}>
                <div className="progress">
                    <div 
                        className="progress-bar progress-bar-striped progress-bar-animated" 
                        role="progressbar" 
                        aria-valuenow={currentValue} 
                        aria-valuemin={0} 
                        aria-valuemax={100} 
                        style={{width: `${currentValue}%`}}></div>
                </div>
                <h5 className='p-2 m-0 text-center'>Chargement...</h5>
            </div>
        </div>
    )
}