export type MenuItem = {
    name: string,
    pathname: string,
    isManual: boolean,
    submenu?: {
        title: string,
        items: MenuItem[]
    },
    external: boolean
}

export const DONATE_LINK = "https://donorbox.org/soutenir-les-actions-de-ysda?default_interval=o";

export const MENU_ITEMS: MenuItem[] = [
    {
        name: "A propos",
        pathname: "",
        isManual: false,
        submenu: {
            title: "A propos de YSDA",
            items: [
                {
                    name: "Notre équipe",
                    pathname: "/team",
                    isManual: false,
                    external: false
                },
                {
                    name: "Partenaires",
                    pathname: "/#partners",
                    isManual: true,
                    external: false
                },
                {
                    name: "Rapport annuel",
                    pathname: "/annual-report",
                    isManual: false,
                    external: false
                },
                {
                    name: "Devenir membre",
                    pathname: "/become-member",
                    isManual: false,
                    external: false
                }
            ]
        },
        external: false
    },
    {
        name: "Blog",
        pathname: "/blogposts",
        isManual: false,
        external: false
    },
    {
        name: "Resources",
        pathname: "/resources",
        isManual: false,
        external: false
    },
    {
        name: "Nous soutenir",
        pathname: DONATE_LINK,
        isManual: true,
        external: true
    }
]

export const ALT_MENU_ITEMS: MenuItem[] = [
    {
        name: "Expériences de terrain",
        pathname: "/blogposts/experiments",
        isManual: false,
        external: false
    },
    {
        name: "Innovations",
        pathname: "/blogposts/innovations",
        isManual: false,
        external: false
    },
    {
        name: "Engagez-vous",
        pathname: "/blogposts/get-involved",
        isManual: false,
        external: false
    },
    {
        name: "Faites un don",
        pathname: DONATE_LINK,
        isManual: true,
        external: true
    }
]