import { baseUrl } from "./ApiUtils";
import Category from "./Category";
import Tag from "./Tag";

export default class Article {
    id!: number
    title!: string
    coverImage!: string
    content!: string
    author!: string
    publishDate?: Date
    lastUpdateDate!: Date
    category!: string
    tags!: Tag[]
    eventDate?: Date
    eventTime?: Date
    eventPlace?: string
    reads!: number
    readTime!: string;

    static clone(instance: Article | undefined) {
        let article: Article = new Article();
        if(instance) {
            article.id = instance.id
            article.title = instance.title
            article.coverImage = instance.coverImage
            article.content = instance.content
            article.author = instance.author
            article.publishDate = instance.publishDate
            article.lastUpdateDate = instance.lastUpdateDate
            article.category = instance.category
            article.tags = instance.tags
            article.eventDate = instance.eventDate
            article.eventTime = instance.eventTime
            article.eventPlace = instance.eventPlace
            article.reads = instance.reads
            article.readTime = instance.readTime
        }
        return article
    }

    validate(categories: Category[]) {
        if(!this.title) {
            return "Veuillez entrer le titre de l'article"
        }
        if(!this.coverImage) {
            return "Veuillez définir une image de couverture"
        }
        if(!this.content) {
            return "L'article est vide!"
        }
        if(!this.author) {
            return "Auteur inconnu"
        }
        if(!this.category) {
            return "Veuillez choisir la catégorie de l'article"
        }
        const c = categories.find(it => it.name === this.category);
        if(!!c && c.events) {
            if(!this.eventPlace) {
                return "Lieu de l'évènement inconnu"
            }
            if(!this.eventDate) {
                return "Date de l'évènement inconnu"
            }
            if(!this.eventTime) {
                return "Heure de l'évènement inconnu"
            }
        }
        return "";
    }

    static map(data: any) {
        let article: Article = new Article();
        article.id = data.id
        article.title = data.title
        article.coverImage = data.coverImage
        article.content = data.content
        article.author = data.author
        article.publishDate = data.publishDate? new Date(data.publishDate) : undefined
        article.lastUpdateDate = new Date(data.lastUpdateDate)
        article.category = data.category
        article.tags = data.tags
        article.eventDate = data.eventDate? new Date(data.eventDate) : undefined
        article.eventTime = data.eventTime? new Date(`${data.eventDate}T${data.eventTime}`) : undefined
        article.eventPlace = data.eventPlace
        article.reads = data.reads
        article.readTime = data.readTime
        return article
    }
}