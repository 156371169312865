import React from 'react';
import PageTemplate from '../components/PageTemplate';

export default function NotFound() {

    return (
        <PageTemplate>
            <div className='w-100 py-5 d-flex flex-column justify-content-center align-items-center'>
                <img src={require("../assets/images/undraw_Page_not_found_re_e9o6.png")} alt="Not found" style={{width: '50%'}}/>
                <h3 className='p-3 my-3 text-center' style={{maxWidth: 625}}>
                    Nous sommes désolés, mais la ressource que vous recherchez est introuvable.
                </h3>
            </div>
        </PageTemplate>
    )
}