import { baseUrl } from "./ApiUtils"

export default class ArticleLight {
    id!: number
    title!: string
    coverImage!: string
    author!: string
    publishDate?: Date
    category!: string
    eventDate?: Date
    eventTime?: Date
    eventPlace?: string
    reads!: number
    readTime!: string;

    static map(data: any) {
        let article: ArticleLight = new ArticleLight();
        article.id = data.id
        article.title = data.title
        article.coverImage = data.coverImage
        article.author = data.author
        article.publishDate = data.publishDate? new Date(data.publishDate) : undefined
        article.category = data.category
        article.eventDate = data.eventDate? new Date(data.eventDate) : undefined
        article.eventTime = data.eventTime? new Date(`${data.eventDate}T${data.eventTime}`) : undefined
        article.eventPlace = data.eventPlace
        article.reads = data.reads
        article.readTime = data.readTime
        return article
    }
}