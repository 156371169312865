import React from 'react'
import CategoryItem from './CategoryItem'
import OwlCarousel from 'react-owl-carousel';
import { DONATE_LINK } from '../../data/Menu';

export default function CategorySlide() {
    const options = {
        loop: false, 
        autoplay: true,
        margin: 15, 
        nav: false,
        rewind: true,
        autoplayHoverPause: true,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 2,
                margin: 10
            },
            992: {
                items: 3
            }
        }
    }
    return (
        <div className="container-fluid" style={{backgroundColor: '#fff'}}>
            <div className="container py-5 catagory-slyder">
                <OwlCarousel className='owl-theme' {...options}>
                    <CategoryItem 
                        title='Nous soutenir'
                        description='Soutenez nos activités en faisant un don.'
                        image={require('../../assets/images/IMG_1641.JPG')}
                        action="Faire un don"
                        actionCommand='/donate'
                        href={DONATE_LINK}
                        btnClass='btn btn-primary'/>
                    <CategoryItem 
                        title='News'
                        description='Recevoir régulièrement les mises-à-jour de nos activités.'
                        image={require('../../assets/images/IMG_0229.jpg')}
                        action="Voir plus"
                        actionCommand='/news'
                        btnClass='btn btn-primary btn-ysda'/>
                    <CategoryItem 
                        title='Blog'
                        description='Recevez les dernières actualités de notre blog.'
                        image={require('../../assets/images/IMG_2427.jpg')}
                        action="Découvrir"
                        actionCommand='/blogposts'
                        btnClass='btn btn-primary btn-ysda-orange'/>
                    <CategoryItem 
                        title='Evènements'
                        description='Découvrez les évènements à venir.'
                        image={require('../../assets/images/DSC_0603.jpg')}
                        action="En savoir plus"
                        actionCommand='/events'
                        btnClass='btn btn-primary btn-ysda-yellow'/>
                </OwlCarousel>
            </div>
        </div>
    )
}