import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom';
import ScrollTrigger from 'react-scroll-trigger';
import CategorySlide from '../components/CategorySlide'
import Counters from '../components/Counters'
import Footer from '../components/Footer'
import Header from '../components/Header'
import Partners from '../components/Partners'
import Projets from '../components/Projets'
import Slider from '../components/Slider'
import AppContext from '../contexts/app.context';

export default function Home() {
    const appContext = useContext(AppContext);
    const [counterOn, setCounterOn] = useState(false);

    return (
        <>
            <div className='cover'>
                <img src={require('../assets/images/IMG_9494.jpg')} alt="Cover" className='w-100'/>
            </div>
            <div className='position-relative' style={{backgroundColor: '#fff'}}>
                <Header/>
                <Slider/>
            </div>
            <Projets/>
            <CategorySlide/>
            <div className='p-5 volontaire d-flex flex-column'>
                <div className="pb-3 px-5">
                    <h6 className="text-primary text-uppercase">Engagez-vous</h6>
                    <h1>Devenir Bénévole</h1>
                </div>
                <p className='pb-3 px-5'>
                YSDA est une organisation dynamique, très active sur les questions de développement durable. Si rejoindre notre équipe vous intéresse et que votre profil correspond aux attentes de la fiche de poste, engagez-vous.
                </p>
                <div className='px-5'>
                    <Link to='/jobs' className='btn ysda-btn-primary' style={{width: 250}}>ENGAGEZ-VOUS</Link>
                </div>
            </div>
            <div className='home-band py-5' id='partners'>
                <div className="text-center pb-3">
                    <h1>Nos Partenaires</h1>
                </div>
                {/* <p className='text-secondary'>
                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque dere santome nida.
                </p> */}
                {<Partners/>}
            </div>
            <ScrollTrigger onEnter={() => {
                if(!appContext.globals.countersTriggered) {
                    setCounterOn(true)
                    appContext.setGlobals({
                        ...appContext.globals,
                        countersTriggered: true
                    })
                }
            }}>
                <div className='home-band counters py-5'>
                    <Counters counterOn={counterOn}/>
                </div>
            </ScrollTrigger>
            <Footer/>
        </>
    )
}